body {
  margin: 0;
  padding: 0;
}
main {
  background-color: #1a1a1a;
}

.App {
  background-color: #141414;
  /* min-height: 100vh; */
  padding-top: 10px;
  overflow-x: hidden;
  color: #ffffff;
}



.App-header {
  display: flex;
  position: relative;
  margin-top: 30px;
  /* align-items: center; */
}

.logo-container {
  display: flex;
}

.logo-container a {
  display: flex;
  margin: 0;
  color: #e50914;
  align-items: center;
  margin-left: 2rem;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.logo-container a:hover {
  transform: scale(1.05);
  text-shadow: 3px 3px 6px rgba(229, 9, 20, 0.4);
}

.nav-links {
  display: flex;
  align-items: center;
  margin-left: 4rem;
  gap: 20px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}


.download-button {
  display: flex;
  padding: 0;
  height: fit-content;
}

.download-button img {
  display: block;
  width: auto;
  height: 40px;
}

.top-right-nav {
  position: absolute;
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  gap: 50px;
  right: 40px;
}

.search-input {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  margin-right: 2.5rem;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.movie-row {
  position: relative;
  transition: all 0.3s ease;
}

.category-title {
  color: #ffffff;
  font-size: 1.5rem;
  padding-left: 40px;
  font-weight: 500;
}

.movie-row-posters {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}

.nav-button::before {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  display: block;
}

.nav-button.left::before {
  transform: rotate(-135deg);
  margin-left: 5px;
}

.nav-button.right::before {
  transform: rotate(45deg);
  margin-right: 5px;
}

.nav-button:hover {
  background: rgba(229, 9, 20, 0.8);
}

.movie-row:hover .nav-button {
  display: flex;
}

.movie-poster {
  flex: 0 0 auto;
  width: 200px;
  margin-right: 10px;
  transition: transform 450ms;
  position: relative;
  transform-origin: center left;
  z-index: 1;
}

.movie-poster:first-child {
  margin-left: 40px;
}

.movie-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 11px;
  text-align: center;
  opacity: 0;
  height: 80px;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.movie-poster:hover .movie-title {
  opacity: 1;
}

.movie-poster img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

.movie-poster:hover {
  transform: scale(1.08);
  cursor: pointer;
  z-index: 2;
}

.movie-row-container {
  position: relative;
  padding: 0px;
}

.movie-row-container::before,
.movie-row-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  pointer-events: none;
}

.movie-row-container::before {
  left: 0;
  background: linear-gradient(to right, #141414 0%, transparent 100%);
}

.movie-row-container::after {
  right: 0;
  background: linear-gradient(to left, #141414 0%, transparent 100%);
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  padding: 0 20px;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.cast-list {
  display: flex;
  overflow-x: hidden;
  padding: 20px 0;
  scroll-behavior: smooth;
}

.cast-item {
  flex: 0 0 auto;
  width: 150px;
  margin-right: 15px;
  text-align: center;
}

.cast-item img {
  width: 120px;
  height: 180px;
  border-radius: 8px;
  object-fit: cover;
}

.cast-item p {
  margin: 5px 0;
  font-size: 14px;
}

.cast-item .character {
  color: #999;
  font-size: 12px;
}

.cast-row {
  position: relative;
  transition: all 0.3s ease;
}

.cast-row-container {
  position: relative;
  padding: 0px;
}

.cast-row-container::before,
.cast-row-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  z-index: 3;
  pointer-events: none;
}

.cast-row-container::before {
  left: 0;
  background: linear-gradient(to right, #141414 0%, transparent 100%);
}

.cast-row-container::after {
  right: 0;
  background: linear-gradient(to left, #141414 0%, transparent 100%);
}

.cast-row:hover .nav-button {
  display: flex;
}

/* Thêm styles mới cho phần đánh giá */


/* Styles cho container chính */
.movie-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Style cho tiêu đề phim */
.movie-title {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}



.info-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 8px;
  transition: transform 0.2s ease;
}

.info-item:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.info-label {
  color: #999;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.info-value {
  color: #ffffff;
  font-size: 1.1rem;
}

.overview {
  color: #e6e6e6;
  line-height: 1.8;
  font-size: 1.1rem;
  margin-bottom: 30px;
  text-align: justify;
}



/* Style cho loading state */
.loading-state {
  text-align: center;
  padding: 40px;
  color: #999;
}

/* Styles cho Actor Detail */
.actor-header {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
  margin-bottom: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding: 30px;
}

.actor-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.actor-header-detail {
  display: flex;
  align-items: center;
}

.actor-header-detail .actor-name {
  font-size: 2.5rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-right: 20px;
}

.actor-info-container {
  display: flex;
  flex-direction: column;
}

.actor-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.biography {
  margin-top: 20px;
}

.biography h2 {
  color: #e50914;
  font-size: 1.8rem;
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(229, 9, 20, 0.3);
  padding-bottom: 10px;
}

.filmography {
  margin-top: 40px;
}

.filmography h2 {
  color: #e50914;
  font-size: 1.8rem;
  margin-bottom: 20px;
  padding-left: 40px;
}

.movie-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.movie-poster:hover .movie-info-overlay {
  opacity: 1;
}

.character {
  color: #999;
  font-size: 0.9rem;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .actor-header {
    grid-template-columns: 1fr;
  }

  .actor-image {
    max-width: 300px;
    margin: 0 auto;
  }
}



.loading-container {
  text-align: center;
  padding: 30px;
}

.search-container {
  position: relative;
  width: 400px;

}

.search-input {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #e50914;
  background: rgba(255, 255, 255, 0.1);
}


.movie-info {
  flex: 1;
}

.movie-info h4 {
  margin: 0;
  color: #fff;
  font-size: 1rem;
}

.movie-info p {
  margin: 5px 0 0;
  color: #999;
  font-size: 0.9rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #141414;
  padding: 15px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.logo {
  color: #e50914;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-wrapper {
  position: relative;
}

.search-result {
  margin-top: 15px;
}

.avatar-container {
  display: flex;
  align-items: center;
  position: relative;
  /* align-items: center; */
}

.avatar-icon {
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgb(233, 210, 210);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1000;
}

.dropdown-item {
  display: block;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #ec5858;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
}

.mini-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.dropdown-divider {
  height: 1px;
  background-color: #eee;
  margin: 4px 0;
}

.logout-btn {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 8px 16px;
  color: #dc3545;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #f8f9fa;
}