.trailer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.movie-header-trailer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.movie-title-trailer {
    font-size: 2.5rem;
    font-weight: bold;
    margin-right: 20px;
}

.favorite-btn {
    display: flex;
    align-items: center;
    margin-left: 30px;
    width: 50px;
    height: 50px;
    background-color: aqua;
    
    
}   

.trailer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    padding: 30px;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.trailer-container iframe {
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.no-trailer {
    color: #999;
    text-align: center;
    padding: 40px;
    font-style: italic;
  }

.movie-info {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    padding: 30px;
    margin: 20px 0;
  }
  
  .movie-info h2 {
    color: #e50914;
    font-size: 1.8rem;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(229, 9, 20, 0.3);
    padding-bottom: 10px;
  }
  
  .movie-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }

.reviews-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 40px;
}

.review-item {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.2s ease;
}

.review-item:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
}

.review-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}

.reviewer-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}

.review-header h4 {
    margin: 0;
    color: #e50914;
    font-size: 1.1rem;
}

.review-date {
    color: #999;
    font-size: 0.9rem;
}

.review-content {
    color: #e6e6e6;
    line-height: 1.6;
    margin: 0;
    font-size: 0.95rem;
}

.read-more {
    display: inline-block;
    color: #e50914;
    text-decoration: none;
    margin-top: 10px;
    font-size: 0.9rem;
    transition: color 0.2s ease;
}

.read-more:hover {
    color: #ff0f1f;
}

.no-reviews {
    text-align: center;
    color: #999;
    padding: 40px;
    font-style: italic;
}

.favorite-icon {
  font-size: 48px;
  color: #ff0000;
  transition: color 0.2s ease;
  margin-top: 8px;
}

.favorite-icon.favorite-active {
  color: #ff0000;
}

.favorite-icon:hover {
  color: #ff0000;
  scale: 1.1;
}