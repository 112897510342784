.list-movies-favorite {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 16px;
    justify-items: center;
    align-items: start;
    padding: 20px;
    overflow-x: hidden;
}

.movie-poster-primary:hover .movie-title {
    opacity: 1;
}

.movie-poster-primary img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 4px;
}

.movie-poster-primary:hover {
    transform: scale(1.08);
    cursor: pointer;
    z-index: 2;
}

.movie-poster-primary {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 10px;
    transition: transform 450ms;
    position: relative;
    transform-origin: center left;
    z-index: 1;
}

