.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.register-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.3) 100%);
}

.register-form {
  background: rgba(23, 32, 42, 0.95);
  padding: 35px;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 420px;
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255,255,255,0.1);
  position: relative;
  z-index: 1;
  animation: fadeIn 0.6s ease-out;
}

.register-form h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
}

.form-group {
  margin-bottom: 25px;
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 15px 20px;
  background: rgba(255,255,255,0.05);
  border: 1px solid rgba(255,255,255,0.1);
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  transition: all 0.3s ease;
  outline: none;
}

.form-group input::placeholder {
  color: rgba(255,255,255,0.5);
}

.form-group input:focus {
  border-color: #64ffda;
  box-shadow: 0 0 15px rgba(100,255,218,0.2);
  background: rgba(255,255,255,0.08);
}

button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(45deg, #64ffda, #48c9b0);
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #0a192f;
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 1px;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 7px 20px rgba(100,255,218,0.3);
}

button:active {
  transform: translateY(1px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background: #64ffda;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.form-group:focus-within::after {
  width: 100%;
}

@media (max-width: 480px) {
  .register-form {
    padding: 25px;
  }

  .register-form h2 {
    font-size: 24px;
  }
} 